import 'lib/firebase.client';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { useEffect } from 'react';

type Props = {
  contentType: string;
  contentId: string;
};

const AnalyticsTracker = ({ contentType, contentId }: Props) => {
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'select_content', {
      content_type: contentType,
      item_id: contentId,
    });
  }, [contentType, contentId]);
  return <></>;
};

export default AnalyticsTracker;
